import React from 'react'
import BackgroundImg from 'gatsby-background-image'
import {graphql,useStaticQuery} from 'gatsby'


export default props => {
    const data = useStaticQuery(graphql`
        { fluid: file(relativePath: {eq: "mayalav6.jpg"}) {
            childImageSharp {
            fluid(quality:100) {
                ...GatsbyImageSharpFluid_withWebp
            } } } } `)
    return (
        <div className="grid text-sulphur text-white grid-cols-1 md:grid-cols-2 
        items-center h-screen md:h-1/2  fondo-azul-mayalav lg:bg-blue-700 xl:bg-blue-500">
            <div className="text-center">
                <h1 className="text-5xl ">{props.titulo}</h1>
                <p>Columna 1</p>
            </div>
            {/* <div className="text-center">Columna 2</div>             */}
            <BackgroundImg className="h-full w-full" fluid={data.fluid.childImageSharp.fluid}/>
        </div>
    )
}