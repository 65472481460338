import React from 'react'
import Layout from '../components/Layout'
import Hero from '../components/Hero'
import SeccionA from '../components/SeccionA'
import SeccionB from '../components/SeccionB'
import SeccionMapa from '../components/SeccionMapa'

const index2 = () => {
    return (
        <Layout>
            <Hero titulo="Homeeeee"/>
            <SeccionA/>
            <SeccionB
            titulo="5 Sucursales en Mérida">
                Esta es la sección B
            </SeccionB>
            <SeccionMapa/>
            <Hero titulo="Siguiente Seccion"/>
            
        </Layout>
   )
}

export default index2

